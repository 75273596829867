import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75c439f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  style: { color: '#ccc', cursor: 'not-allowed' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_editButton = _resolveComponent("editButton")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_yTable = _resolveComponent("yTable")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_listLayout = _resolveComponent("listLayout")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_listLayout, {
      num: _ctx.selectedRowKeys.length,
      onEmpty: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedRowKeys = []))
    }, {
      selectList: _withCtx(() => [
        _createVNode(_component_a_form, {
          layout: "inline",
          model: _ctx.selectFrom.data,
          "label-col": { style: { width: '100px' } }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItemList, (item) => {
              return (_openBlock(), _createBlock(_component_a_form_item, {
                key: item.prop,
                label: item.label,
                name: item.prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    value: _ctx.selectFrom.data[item.prop],
                    "onUpdate:value": ($event: any) => ((_ctx.selectFrom.data[item.prop]) = $event),
                    formItemContent: item
                  }, null, 8, ["value", "onUpdate:value", "formItemContent"])
                ]),
                _: 2
              }, 1032, ["label", "name"]))
            }), 128)),
            _createVNode(_component_a_form_item, {
              class: "button",
              label: " ",
              colon: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.selectList
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, { onClick: _ctx.reset }, {
                  default: _withCtx(() => [
                    _createTextVNode("重置")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      buttonList: _withCtx(() => [
        _createVNode(_component_a_upload, {
          "custom-request": _ctx.importFile,
          showUploadList: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_editButton, {
              icon: _ctx.buttonObj['compete-import'],
              text: "导入"
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["custom-request"]),
        _createVNode(_component_editButton, {
          icon: _ctx.buttonObj['compete-delete'],
          text: "删除",
          type: "danger",
          onClick: _ctx.delData
        }, null, 8, ["icon", "onClick"]),
        _createElementVNode("span", {
          class: "iconfont icon-lieshezhi pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowDrawer = true))
        })
      ]),
      table: _withCtx(() => [
        _createVNode(_component_yTable, {
          dataSource: _ctx.dataSource,
          columns: _ctx.columns,
          selectedRowKeys: _ctx.selectedRowKeys,
          "onUpdate:selectedRowKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRowKeys) = $event)),
          currentConfigure: _ctx.currentConfigure
        }, {
          bodyCell: _withCtx(({ column, record }) => [
            (column.dataIndex === 'imageUrl')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (record.imageUrl)
                    ? (_openBlock(), _createBlock(_component_a_image, {
                        key: 0,
                        width: 36,
                        height: 36,
                        src: 
                record.imageUrl.slice(0, 4) === 'http'
                  ? record.imageUrl
                  : 'http://' + record.imageUrl
              ,
                        fallback: _ctx.fallbackImg
                      }, null, 8, ["src", "fallback"]))
                    : _createCommentVNode("", true)
                ], 64))
              : (column.dataIndex === 'operation')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (record.imageUrl || record.innerCode)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "edit",
                          onClick: ($event: any) => (_ctx.detailData(record))
                        }, "详情", 8, _hoisted_2))
                      : (_openBlock(), _createElementBlock("span", _hoisted_3, "详情"))
                  ]))
                : (column.dataIndex === 'enableStatus')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("i", {
                        class: "iconfont icon-xinhao",
                        style: _normalizeStyle({
                'font-size': '10px',
                color: record.enableStatus === 1 ? '#13c2c2' : '#ccc',
                'margin-right': '5px',
              })
                      }, null, 4),
                      _createTextVNode(" " + _toDisplayString(record.enableStatus === 1 ? '已启用' : '未启用'), 1)
                    ], 64))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["dataSource", "columns", "selectedRowKeys", "currentConfigure"]),
        _createVNode(_component_Drawer, {
          visible: _ctx.isShowDrawer,
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShowDrawer) = $event)),
          "origin-columns": _ctx.columns,
          "page-name": "competingGoodsData",
          onUpdateColumns: _ctx.updateColumns
        }, null, 8, ["visible", "origin-columns", "onUpdateColumns"])
      ]),
      _: 1
    }, 8, ["num"]),
    _createVNode(_component_a_modal, {
      okText: `确认（${_ctx.importSelectedRowKeys.length}）`,
      visible: _ctx.importVisible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.importVisible) = $event)),
      title: "竞品信息批量导入",
      onOk: _ctx.importOk,
      onCancel: _ctx.cancel
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          bordered: "",
          dataSource: _ctx.imporDataSource,
          "row-selection": {
        selectedRowKeys: _ctx.importSelectedRowKeys,
        onChange: _ctx.onSelectChange,
        align: 'left',
      },
          columns: _ctx.imporColumns,
          pagination: false,
          rowKey: 'id'
        }, null, 8, ["dataSource", "row-selection", "columns"]),
        _createVNode(_component_a_pagination, {
          class: "tablePagination",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentPage) = $event)),
          pageSize: _ctx.pageSize,
          "onUpdate:pageSize": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageSize) = $event)),
          "show-size-changer": "",
          showQuickJumper: "",
          total: _ctx.dataCount,
          onShowSizeChange: _ctx.onShowSizeChange,
          onChange: _ctx.onChange,
          showTotal: _ctx.showTotal,
          pageSizeOptions: ['10', '20', '30'],
          hideOnSinglePage: true
        }, null, 8, ["current", "pageSize", "total", "onShowSizeChange", "onChange", "showTotal"])
      ]),
      _: 1
    }, 8, ["okText", "visible", "onOk", "onCancel"])
  ], 64))
}