/*竞品信息类*/
import { ReqPage } from '../../common/reqPage'
export class CompetingGoods {
  id: string | undefined //主键
  productModel: string | undefined //怡合达规格型号
  modelCode: string | undefined //物料编号
  productName: string | undefined //物料名称
  accompanyId: string | undefined //同行id
  accompanyName: string | undefined //同行名称
  competitorModel: string | undefined //竞品规格
  competitorModelName: string | undefined //竞品名称
  productUrl: string | undefined //竞品地址
  innerCode: string | undefined //竞品编码
  imageUrl: string | undefined //图片地址
  enableStatus: number | undefined //启用状态;0:未启用 1:已启用
  isDeleted: number | undefined //是否删除;0:未删除 1:已删除
  createdBy: string | undefined //创建人
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined //更新人
  updatedTime: string | undefined //更新时间
  constructor() {
    this.id = undefined
    this.productModel = undefined
    this.modelCode = undefined
    this.productName = undefined
    this.accompanyName = undefined
    this.accompanyId = undefined
    this.competitorModel = undefined
    this.competitorModelName = undefined
    this.productUrl = undefined
    this.innerCode = undefined
    this.imageUrl = undefined
    this.enableStatus = undefined
    this.isDeleted = undefined
    this.createdBy = undefined
    this.createdTime = undefined
    this.updatedBy = undefined
    this.updatedTime = undefined
  }
}
export class ReqCompetingGoods extends ReqPage {
  data: CompetingGoods
  constructor() {
    super()
    this.data = new CompetingGoods()
  }
}
export class ResCompetingGoods extends ReqPage {
  data: CompetingGoods[] | undefined
  constructor() {
    super()
    this.data = undefined
  }
}
export interface ResBatchCompetingGoods {
  success: CompetingGoods[]
  failure: string[]
}
export interface CompetitorProductDetailVO {
  id: string //id
  parentId: string //父id
  priceUnit: string //价格单位
  taxPrice: string //含税单价
  beforeTaxPrice: string //未税单价
  lowestPrice: string //历史最低单价
  highestPrice: string //历史最高单价
  deliveryTime: string //单价发货时间
  isDiscount: number //是否有折扣（0-没有折扣；1-有折扣）
  imageIdList: string //竞品图片id集合;最多3个以 “ ，”隔开
  discountTable: string //数量折扣json数据
  propertyTable: string //竞品属性json数据
  isDeleted: 0 | 1 //是否删除（0-未删除；1-已删除）
  createdBy: string //创建人
  createdTime: string //创建时间
  updatedBy: string //	更新人
  updatedTime: string //更新时间
}
export interface CompetingGoodsDetailItem {
  competitorProductBasicInfoVO: {
    accompanyName: string //同行名称
    competitorModel: string //	规格型号
    competitorModelName: string //竞品名称
    innerCode: string //竞品料号
  }
  competitorProductDetailVO: CompetitorProductDetailVO
}
// 竞品详情
export interface CompetingGoodsDetail {
  yhdProductInfoVO: {
    productModel: string //怡合达物料规格
    modelCode: string //物料编号
    productName: string //物料名称
    dptName: string //所属部门
    empName: string //部门经理
  }
  competitorProductDetailAndBasicInfoVOList: CompetingGoodsDetailItem[]
}
