
import { defineComponent, toRefs, ref, computed, reactive, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import FormItem from '@/components/formItem/index.vue'
import {
  CompetingGoods,
  ReqCompetingGoods,
} from '@/model/competitiveAnalysis/competingGoodsData/competingGoods'
import editButton from '@/components/editButton/index.vue'
import infoApi from '@/axios/api/info'
import { message } from 'ant-design-vue'
import { useRouter, useRoute, onBeforeRouteUpdate } from 'vue-router'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import Data from '@/model/common/Data'
import { deleteModal } from '@/utils/deleteModal'
import accompanyApi from '@/axios/api/accompany'
import { RegPeerClass } from '@/model/competitiveAnalysis/peerData/peerData'
import demandApi from '@/axios/api/demandApi'
import { FormItemListType } from '@/types/comm'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
import Drawer from '@/components/Drawer/Drawer.vue'
export default defineComponent({
  components: {
    listLayout,
    FormItem,
    editButton,
    yTable,
    Drawer,
  },
  setup() {
    onBeforeRouteUpdate((to, from) => {
      console.log(from.name)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })
    const peerTable = ref()

    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }

    //获取路由
    let router = useRouter()
    let route = useRoute()
    //获取store
    const store = useStore()
    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new ReqCompetingGoods(),
      dataSource: [] as CompetingGoods[],
      columns: [
        {
          title: '规格型号',
          dataIndex: 'productModel',
          width: 150,
          key: 'productModel',
          resizable: true,
        },
        {
          title: '物料编号',
          dataIndex: 'modelCode',
          width: 150,
          key: 'modelCode',
          resizable: true,
        },
        {
          title: '物料名称',
          dataIndex: 'productName',
          width: 150,
          key: 'productName',
          resizable: true,
        },
        {
          title: '同行名称',
          dataIndex: 'accompanyName',
          width: 130,
          key: 'accompanyName',
          resizable: true,
        },
        {
          title: '竞品规格',
          dataIndex: 'competitorModel',
          width: 150,
          key: 'competitorModel',
          resizable: true,
        },
        {
          title: '竞品名称',
          dataIndex: 'competitorModelName',
          width: 150,
          key: 'competitorModelName',
          resizable: true,
        },
        {
          title: '竞品编码',
          dataIndex: 'innerCode',
          width: 100,
          key: 'innerCode',
          resizable: true,
        },
        {
          title: '图片',
          dataIndex: 'imageUrl',
          width: 80,
          key: 'imageUrl',
          resizable: true,
        },
        {
          title: '启用状态',
          dataIndex: 'enableStatus',
          width: 90,
          key: 'enableStatus',
          resizable: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 120,
          key: 'operation',
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'productModel',
          placeholder: '请输入怡合达规格型号',
          label: '规格型号',
        },
        {
          type: 'subInput',
          prop: 'productName',
          placeholder: '请输入物料名称',
          label: '物料名称',
        },
        {
          type: 'subInput',
          prop: 'modelCode',
          placeholder: '请输入物料编号',
          label: '物料编号',
        },
        {
          type: 'subInput',
          prop: 'competitorModel',
          placeholder: '请输入竞品规格',
          label: '竞品规格:',
        },
        {
          type: 'subInput',
          prop: 'accompanyName',
          placeholder: '请输入同行名称',
          label: '同行名称:',
        },
        {
          type: 'subInput',
          prop: 'competitorModelName',
          placeholder: '请输入竞品名称',
          label: '竞品名称:',
        },
        {
          type: 'subInput',
          prop: 'innerCode',
          placeholder: '请输入竞品编码',
          label: '竞品编码:',
        },
        {
          type: 'subSelect',
          prop: 'enableStatus',
          placeholder: '请输入启用状态',
          label: '启用状态:',
          optionList: [
            {
              value: 0,
              label: '未启用',
            },
            {
              value: 1,
              label: '启用',
            },
          ],
        },
      ] as FormItemListType<CompetingGoods>,
    })
    // //如果有缓存的查询数据 则采用缓存的数据
    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
    }
    console.log(pageData.selectFrom)

    const buttonObj = store.getters.getButtonObj
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      infoApi
        .InfoList(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          pageData.selectedRowKeys = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectList = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    initeList()
    //新增按钮
    const detailData = (detaildForm: Data) => {
      router.push({
        name: 'competingGoodsDetail',
        query: {
          productModel: detaildForm.productModel as string,
          modelCode: detaildForm.modelCode as string,
          productName: detaildForm.productName as string,
          tabName: detaildForm.accompanyName as string,
        },
      })
    }
    //编辑按钮
    const updateData = () => {
      message.warning('开发中')
    }
    //删除按钮
    const delData = () => {
      if (pageData.selectedRowKeys.length === 0) {
        message.warning('请至少选择一项数据！')
        return
      }
      const ok = () => {
        let data = pageData.dataSource
          .filter((item) => {
            return pageData.selectedRowKeys.indexOf(item.id as string) !== -1
          })
          .map((item) => {
            return {
              id: item.id,
              accompanyId: item.accompanyId,
            }
          })
        infoApi
          .InfoDelete(data)
          .then(() => {
            message.success('删除成功')

            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      deleteModal(ok)
    }
    //跳转至账号设置页面
    const accountSet = (id: string) => {
      console.log(`跳转至id为${id}页面`)
      router.push(`/competitiveAnalysis/peerData/accountInformation/${id}`)
    }
    //搜索
    const search = () => {
      console.log(pageData.selectFrom)
    }
    //
    const reset = () => {
      pageData.selectFrom.data = new CompetingGoods()
      selectList()
    }
    interface FileItem {
      uid: string
      name?: string
      status?: string
      response?: string
      url?: string
      preview?: string
      size: number
      file: string | Blob | File | FileItem
    }
    const importData = reactive({
      importVisible: false,
      imporDataSource: [],
      imporColumns: [
        {
          title: '同行名称',
          dataIndex: 'competitorName',
          key: 'competitorName',
          width: 250,
          align: 'center',
        },
      ],
      importSelectedRowKeys: [] as string[],
      pageSize: 10,
      currentPage: 1,
      onChange(page: number, pageSize: number) {
        importData.pageSize = pageSize
        importData.currentPage = page
        getAccompanyList(page, pageSize)
      },
      onShowSizeChange(current: number, size: number) {
        importData.pageSize = size
        importData.currentPage = current
        getAccompanyList(current, size)
      },
      showTotal(total: string) {
        return `共计 ${total} 条记录`
      },
      dataCount: 0,
      onSelectChange(changableRowKeys: string[]) {
        importData.importSelectedRowKeys = changableRowKeys
      },
      importForm: {
        file: '',
        list: [] as string[],
      },
      importFile(param: FileItem) {
        const formData = new FormData()
        formData.append('file', param.file as Blob)
        demandApi
          .uploadFile(formData)
          .then((res) => {
            importData.importForm.file = res
            importData.importVisible = true
          })
          .catch((err) => {
            message.error(err || '上传失败')
          })
      },
      importOk() {
        if (importData.importSelectedRowKeys.length === 0) {
          message.warning('至少选择一个')
          return
        }
        importData.importForm.list = importData.importSelectedRowKeys
        accompanyApi
          .CompeteImport(importData.importForm)
          .then(() => {
            message.success('成功')
            importData.importForm.list = []
            importData.importForm.file = ''
            importData.importVisible = false
            initeList()
          })
          .catch((err) => {
            message.error(err || '失败')
          })
      },
      cancel() {
        importData.importForm.list = []
        importData.importForm.file = ''
      },
    })
    const getAccompanyList = (current?: number, size?: number) => {
      let data
      if (current && size) {
        data = { currentPage: current, pageSize: size, data: {} } as RegPeerClass
      } else {
        data = {} as RegPeerClass
      }
      accompanyApi
        .AccompanyList(data)
        .then((res) => {
          importData.imporDataSource = []
          if (res.data) {
            Object.assign(importData.imporDataSource, res.data)
          }
          importData.currentPage = res.currentPage as number
          importData.pageSize = res.pageSize as number
          importData.dataCount = res.dataCount as number
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    getAccompanyList()
    return {
      ...toRefs(pageData),
      detailData,
      ...toRefs(importData),
      peerTable,
      accountSet,
      updateData,
      search,
      fallbackImg: require('@/assets/img/fallbackImg.svg'),
      selectList,
      delData,
      reset,
      buttonObj,
      currentConfigure,
      updateColumns,
    }
  },
})
